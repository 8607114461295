export const WAIT_RESPONSE_TIMEOUT = 6000;

export const MODULES = {
  RECON: 'Recon',
  SYMBOLDB: 'SymbolDB',
  SYMBOLDB_UI: 'SymbolDBUI',
  WEBBO: 'WebBo',
  NOTIFICATIONS_SHAPER: 'NS',
  CRM: 'Crm',
  CRM_UI_REACT: 'CrmUI',
  CPRM: 'Cprm',
  WORKFLOW: 'Workflow',
  REPORTING: 'Reporting',
  CORPORATE_ACTIONS_UI: 'CAS',
  BRANDING_UI: 'Branding',
  ORDER_MANAGEMENT: 'orderManagement',
  REMUS: 'Remus',
  STRESS_TEST: 'StressTest',
} as const;

export const SERVICE_PATHS = ['update'];

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
