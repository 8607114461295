import { useMemo, useState } from 'react';

import { useCrmService } from '../components/services/Crm.service';
import {
  TCRMMenuItem,
  TCrmBookmarksData,
} from '../components/services/Types/bookmarks.types';

import { CRM_UI_SERVICE_URL_PREFIX } from './const';
import { getAddedBookmarkTabs, getBookmarksDistributedByTabs } from './helpers';
import { useMenuConfig } from './useMenuConfig';

let cachedCRMBookmarksData: TCrmBookmarksData = null;

// hook for new crm ui
export const useCRMBookmarks = () => {
  const { getBookmarksCrmUi } = useCrmService();
  const { getMenuConfig } = useMenuConfig();

  const menuConfig = getMenuConfig();

  const [crmBookmarksState, setCRMBookmarksState] = useState<TCRMMenuItem[]>();

  const getCRMBookmarkData = async (forceUpdate = false) => {
    if (!cachedCRMBookmarksData || forceUpdate) {
      cachedCRMBookmarksData = await getBookmarksCrmUi();
    }
    return cachedCRMBookmarksData;
  };

  const request = async (forceUpdate = false) => {
    try {
      const resp = await getCRMBookmarkData(forceUpdate);

      if (resp && resp.data) {
        const addedTabs = getAddedBookmarkTabs(
          resp.data,
          menuConfig,
          CRM_UI_SERVICE_URL_PREFIX,
        );
        setCRMBookmarksState(
          getBookmarksDistributedByTabs(addedTabs, CRM_UI_SERVICE_URL_PREFIX),
        );
      }
    } catch (e) {
      console.error('CRM bookmarks error', e);
    }
  };

  return useMemo(
    () => ({
      crmBookmarks: crmBookmarksState,
      requestCRMBookmarks: request,
    }),
    [crmBookmarksState],
  );
};
